// src/Impressum.tsx
import React from 'react';
import './Impressum.css';

const Impressum: React.FC = () => {
  return (
    <div className="Impressum">
      <h1>Impressum</h1>
      <p>Simulacron GmbH</p>
      <p>Schmiedestraße 11</p>
      <p>25899 Niebüll</p>
      <p>Telefon: +49 4661 7089163</p>
      <p>E-Mail: <a href="mailto:info@simulacron.ai">info@simulacron.ai</a></p>
      <p>Vertreten durch den Geschäftsführer: Cai Xiang</p>
      <p>Registergericht: Amtsgericht Flensburg</p>
      <p>Registernummer: HRB 17172 FL</p>
    </div>
  );
};

export default Impressum;
