import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './s1.jpg';
import './App.css';
import Impressum from './Impressum';

const Home: React.FC = () => (
  <div>
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
    </header>
  </div>
);

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
        <footer className="App-footer">
          <p>
            <Link to="/impressum" style={{ color: 'white' }}>Impressum</Link>
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
